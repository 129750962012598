import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { NavLink } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.main
  }
}));

const Menu = ({open, toggleMenu, items}) => {
    const classes = useStyles();

    const list = (
        <div
            className={classes.list}
            role="presentation"
            onClick={() => toggleMenu(false)}
            onKeyDown={() => toggleMenu(false)}
        >
        <List>
            <NavLink 
                    className={classes.link} to='/'
                    activeStyle={{
                        borderBottomStyle: 'solid',
                        borderBottomColor: '#EF233C'
                    }}>
                <ListItem button key={'home'}>              
                        <ListItemIcon> <ChevronRightIcon /> </ListItemIcon>
                        <ListItemText primary={'Home'} />           
                </ListItem>
            </NavLink>
            {items.map((item) => (
                <NavLink 
                    key={item.label}
                    className={classes.link} to={item.route}
                    activeStyle={{
                        borderBottomStyle: 'solid',
                        borderBottomColor: '#EF233C'
                    }}>
                    <ListItem button>             
                        <ListItemIcon> <ChevronRightIcon /> </ListItemIcon>
                        <ListItemText primary={item.label} />       
                    </ListItem>
            </NavLink>
            ))}
        </List>
        </div>
    );

    return (
            <div>
                <React.Fragment key={'menu'}>
                    <Drawer anchor={'left'} open={open} onClose={() => toggleMenu(false)}>
                        {list}
                    </Drawer>
                </React.Fragment>
            </div>
        );
    }
 export default Menu