import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
    headerText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
        }
    }
}))

const Services = () => {
    const classes = useStyles()

    return (
        <Box 
            mx={{xs: 5, sm: 5, md: 10}} 
            my={{xs: 2, sm: 2, md: 4}} 
            height="400px"
        >
            <Box 
                width={1}
                display="flex"
            >
            <Typography variant="h4" className={classes.headerText}>
                Servicios
            </Typography>

            </Box>
        </Box>
    )
}

export default Services