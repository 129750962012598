import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';

const requestImageFile = require.context('../../../../assets/img/logo', true, /.*$/);

const useStyles = makeStyles((theme) => ({
    img: {
        height: '100px',
        width: '100px',
        marginRight: '2%'
    },
    title: {
        color: "#FFFFFF",
        marginRight: '5px',
        fontWeight: '900'
    },
    subtitle: {
        color: "#FFFFFF",
        alignSelf: 'center'
    },
    item: {
        color: "#FFFFFF",
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.5rem',
        }
    },
    icon: {
        marginRight: '10px'
    }
}))

const Footer = () => {
    const classes = useStyles();

    return (
        <Box 
            component="footer"
            height='200px'
            bgcolor="secondary.main"
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            px='24px'
            py="10px"
        >
            <Box 
              width={1}
              display='flex'
              alignItems='center'
            >
                <Avatar alt="corvp industries" src={requestImageFile('./corvp_logo.jpeg').default} className={classes.img} />
                <Box>
                    <Box 
                        display="flex"
                    >
                        <Typography variant="h5" className={classes.title}>
                            CORVP
                        </Typography>
                        <Typography variant="caption" className={classes.subtitle}>
                            Industries
                        </Typography>
                    </Box>
                    <Box 
                        display="flex"
                        flexDirection="column"
                    >
                        <Typography variant="overline" className={classes.item}>
                            <LocationOnIcon className={classes.icon}/> Av Santa Fe 5764, Bella Vista, Buenos aires
                        </Typography>
                        <Typography variant="overline" className={classes.item}>
                            <PhoneIcon className={classes.icon}/> <b>Ventas: </b> &nbsp; 11 3074-1360
                        </Typography>
                        <Typography variant="overline" className={classes.item}>
                            <PhoneIcon className={classes.icon}/> <b>Administracion: </b> &nbsp; 11 2567-8739 / 11 6748-1414 / 11 6274-1959
                        </Typography>
                        <Typography variant="overline" className={classes.item}>
                            <MailIcon className={classes.icon}/> corpvindustries@gmail.com
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Footer