import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
      minWidth: 345,

      [theme.breakpoints.down('sm')]: {
        marginTop: '10px',
        marginBottom: '20px'
      }
    },
    media: {
      height: 140,
    }
  }));

const CardCustom = ({title, desc, image, redirect}) => {
    const classes = useStyles();
    const history = useHistory();

  return (
    <Card 
      className={classes.root}
      onClick={() => history.push(redirect)}
    >
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={image}
          title="aberturas"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {/* Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
            across all continents except Antarctica */}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default CardCustom