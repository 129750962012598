import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const requestImageFile = require.context('../../../../assets/img/muestras', true, /.*$/);

const useStyles = makeStyles((theme) => ({
    paper: {
        height: '150px',
        width: '200px',
        margin: '10px 10px 10px 10px'
    },
    img: {
        height: '100%',
        width: '100%'
    },
    headerText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '3rem',
            color: '#FFFFFF'
        }
    },
    headerBox: {
        [theme.breakpoints.down('sm')]: {
            backgroundImage:`url(${requestImageFile('./abertura1.png').default})`
        }
    }
}))

const Header = () => {
    const classes = useStyles()

    return (
        <Box 
            height='400px'
            display='flex'
            alignItems= 'center'
            px='24px'
            bgcolor= '#EDF2F4'
            className={classes.headerBox}
        >
        <Box 
            width={1}
            display="flex"
            justifyContent="center"
        >
            <Typography variant="h2" className={classes.headerText}>
                Puertas, <br /> 
                Ventanas y<br />
                Cerramientos 
            </Typography>
        </Box>
        <Box 
            width={1}
            display={{xs: 'none', sm: 'none', md: 'flex'}}
            justifyContent="center"
        >
            <Box 
                display="flex"
                flexDirection="column"
                justifyContent="center"
            >
                <Paper elevation={3} className={classes.paper}>
                    <img src={requestImageFile('./abertura1.png').default} className={classes.img} alt="aberturas"/>
                </Paper>
                <Paper elevation={3} className={classes.paper} >
                    <img src={requestImageFile('./abertura2.jpg').default} className={classes.img} alt="aberturas"/>
                </Paper>
            </Box>
            <Box 
                display="flex"
                flexDirection="column"
                justifyContent="center"
            >
                <Paper elevation={3} className={classes.paper}>
                    <img src={requestImageFile('./abertura3.jpg').default} className={classes.img} alt="aberturas"/>
                </Paper>
            </Box>
            </Box>
        </Box>
    )
}

export default Header