import React from 'react'
import Box from '@material-ui/core/Box';
import Header from './Header'
import Middle from './Middle'
import Cards from './Cards'
import End from './End'

const Home = () => {
    return (
        <Box>
            <Header />
            <Middle />
            <Cards />
            <End />
        </Box>
    )
}

export default Home