import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Fade from 'react-reveal/Fade';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
    marginBottom: '10px'
  },
  img: {
    height: '200px',
    width: '200px'
  },
  header: {
  //  backgroundColor: '#EDF2F4'
  },
  content: {
    display: 'flex',
    flexDirection: 'row'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  button: {
    backgroundColor: '#EF233C',
    color: '#FFFFFF',
    '&:hover, &:focus': {
        backgroundColor: '#ffe4c4'
    },
    '& a' : {
      display: 'flex',
      textDecoration: 'none',
      color: '#FFFFFF'
    }
  }
}));

const ProductCardDesk = ({title, caracts, tipos, image, dvh = false, dvhFile}) => {
  const classes = useStyles();

  return (
    <Fade left>
    <Card className={classes.root} elevation={3}>
          <CardHeader 
              title={title}
              className={classes.header}
          />
      <Box 
        display='flex'
        px='10px'>
        <Box 
            display='flex'
            alignItems='center'
        >
            <img src={image} className={classes.img} alt="abertura producto"/>
        </Box>
  
        <Box>
            <CardContent className={classes.content}>
                <Box px='10px' width='50%'>
                    <Typography variant="h6" className={classes.headerText}>
                        Caracteristicas
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {caracts}
                    </Typography>
                </Box>
                <Box px='10px' width='50%'>
                    <Typography variant="h6" className={classes.headerText}>
                        Tipologias disponibles
                    </Typography>
                    {tipos.map((item) => (
                    <Typography key={item} variant="body2" color="textSecondary" component="p">
                    {item}
                    </Typography>
                ))}
                </Box>
            </CardContent>
            {
              dvh === true && <CardActions className={classes.actions}>
                  <Button
                  className={classes.button} 
                  size="small" 
                  color="primary">
                      <a href={dvhFile}>
                        <GetAppIcon /> Doble de vidrio
                      </a>
                  </Button>
              </CardActions>
            }
        </Box>
     </Box>
    </Card>
    </Fade>

  );
}

export default ProductCardDesk