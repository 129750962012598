import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Fade from 'react-reveal/Fade';
import { useHistory } from 'react-router-dom';

const requestImageFile = require.context('../../../../assets/img/ilustrations', true, /.*$/);

const useStyles = makeStyles((theme) => ({
    paper: {
        height: '150px',
        width: '200px',
        margin: '10px 10px 10px 10px'
    },
    img: {
        height: '100%',
        width: '100%'
    },
    button: {
        backgroundColor: '#EF233C',
        color: '#FFFFFF',
        '&:hover, &:focus': {
            backgroundColor: '#ffe4c4'
        }
    },
}))

const End = () => {
    const classes = useStyles()
    const history = useHistory()

    return (
        <Box 
            display="flex" 
            justifyContent="center"
            flexDirection="column"
            my="30px">
            <Box mx="auto">
                <Fade left>
                    <Typography variant="h4">
                        Podemos asesorarte
                    </Typography>
                </Fade>
            </Box>
            <Box my={1} mx="auto" textAlign="center">
                <Fade left>
                    <Typography variant="body1" >
                        Nos dedicamos a la fabricación de aberturas de aluminio de primera calidad.<br/>
                        Envianos tu consulta para poder asesorarte de la mejor manera según tus necesidades.
                    </Typography>
                </Fade>
            </Box>
            <Box mx="auto">
                <Fade left>
                    <Paper elevation={0} className={classes.paper} mx="auto">    
                        <img src={requestImageFile('./collab.svg').default} className={classes.img} alt="aberturas asesoramiento"/>
                    </Paper>
                </Fade>
            </Box>
            <Box mx="auto" mt={1} mb={5}>
                <Fade>
                    <Button 
                        className={classes.button} 
                        size="large" 
                        color="primary"
                        onClick={() => history.push('contact')}>
                        Contactenos
                    </Button>
                </Fade>
            </Box>
        </Box>
    )
}

export default End