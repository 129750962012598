import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Fade from 'react-reveal/Fade';
import * as Email from 'emailjs-com';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';

const useStyles = makeStyles((theme) => ({
    container: {
        display:"flex",
        justifyContent:'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    headerText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
        }
    },
    textField: {
        width: '200px'
    },
    textArea: {
        width: '500px',
        [theme.breakpoints.down('sm')]: {
            width: '250px',
        }
    },
    button: {
        width: '200px',
        height: '50px',
        backgroundColor: '#EF233C',
        color: '#FFFFFF',
        '&:hover, &:focus': {
            backgroundColor: '#ffe4c4'
        },
        '&.Mui-disabled': {
            color: '#FFFFFF',
            backgroundColor: '#808080'
        }
    },
    item: {
        color: theme.palette.secondary.main,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.6rem',
        }
    },
    icon: {
        color: theme.palette.primary.main,
        marginRight: '10px'
    }
}))

const Contact = () => {
    const classes = useStyles()

    const [ data, setData ] = React.useState({
        name: '',
        email: '',
        message: ''
    })

    const [ sending, setSending ] = React.useState(false)

    const updateRequest = (prop, value) => {
        let newData = {...data}
        newData[prop] = value
        setData(newData)
    }

    const clear = () => {
        setData({
            name: '',
            email: '',
            message: ''
        })
    }

    const sendEmail = () => {
        setSending(true)
        Email.init("user_2ZVXUFzt2lTjwum4TDcLL")

        Email.send("service_ee5ni5o","template_gwk9l1d",{
            name: data.name,
            email: data.email,
            message: data.message,
        })
        .then((res) => {
            setSending(false)
            toast.success("Consulta enviada")
            clear()
        })
        .catch((err) => {
            setSending(false)
            toast.error("Error al enviar consulta, por favor reintente")
        })
    }

    React.useEffect(() => {
        return (
            clear()
        )
    },[])

    return (
        <Box 
            mx={{xs: 5, sm: 5, md: 10}} 
            my={{xs: 2, sm: 2, md: 4}} 
            height="600px"
        >
            <Fade>
            <Box 
                width={1}
                display="flex"
                flexDirection='column'
            >
                <Typography variant="h4" className={classes.headerText}>
                    Contactenos
                </Typography>

                <Box 
                    width={1}
                    className={classes.container}>
                    <Box
                        display='flex'
                        flexDirection='column'
                        pt='10px'
                        justifyContent='space-evenly'>
                            <Typography variant="overline" className={classes.item}>
                                <LocationOnIcon className={classes.icon}/> Av Santa Fe 5764, Bella Vista, Buenos aires
                            </Typography>
                            <Typography variant="overline" className={classes.item}>
                                <PhoneIcon className={classes.icon}/> <b>Ventas: </b> &nbsp; 11 3074-1360
                            </Typography>
                            <Typography variant="overline" className={classes.item}>
                                <PhoneIcon className={classes.icon}/> <b>Administracion: </b> &nbsp; 11 2567-8739 / 11 6748-1414 / 11 6274-1959
                            </Typography>
                            <Typography variant="overline" className={classes.item}>
                                <MailIcon className={classes.icon}/> corpvindustries@gmail.com
                            </Typography>
                    </Box>
                <Box
                    display='flex'
                    flexDirection='column'
                    pt='20px'
                >
                     <Typography variant="body2">
                       O Escriba su consulta y nos contactaremos con usted a la brevedad
                    </Typography>
                    <Box
                        display='flex'
                        flexDirection='column'
                        pt='20px'
                    >
                        <Box 
                            display='flex' 
                            >
                            <Box 
                                py='10px'
                                pr='20px'>
                                <TextField 
                                    id="name" 
                                    label="Nombre" 
                                    variant="outlined" 
                                    value={data.name}
                                    onChange={(e) => updateRequest('name', e.target.value)}
                                />
                            </Box>
                            <Box  
                                py='10px'
                                pr='20px'>
                                <TextField 
                                    id="email" 
                                    label="Email" 
                                    variant="outlined" 
                                    value={data.email}
                                    onChange={(e) => updateRequest('email', e.target.value)}
                                />
                            </Box>
                        </Box>
                    <Box 
                        py='10px'
                        pr='20px'>
                        <TextField 
                            id="message" 
                            label="Consulta" 
                            variant="outlined" 
                            multiline
                            value={data.message}
                            rows={4}
                            className={classes.textArea}
                            onChange={(e) => updateRequest('message', e.target.value)}
                        />
                    </Box>
                    <Box pt='10px'>
                        <Button 
                            className={classes.button}
                            size="large" 
                            color="primary"
                            disabled={
                                data['name'] === '' ||
                                data['email'] === '' ||
                                data['message'] === ''
                            }
                            onClick={() => sendEmail()}>
                           {sending ? <CircularProgress color="secondary"/> : 'Enviar consulta'}  
                        </Button>
                    </Box>
                    </Box>
                </Box>
                </Box>
            </Box>
            </Fade>
        </Box>
    )
}

export default Contact