import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fade from 'react-reveal/Fade';

const requestImageFile = require.context('../../../../assets/img/logo', true, /.*$/);

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: '#FFFFFF'
  },
  img: {
    height: '200px',
    width: '200px'
  },
  full: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 101,
    backgroundColor: '#FFFFFF'
},
}));

const Splash = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setTimeout(() => {
        handleClose()
    }, 1500)
  },[])

  return open ? (
    <div className={classes.full}>
      <Fade>
        <img src={requestImageFile('./corvp_logo.jpeg').default} className={classes.img} alt="Corvp industries" />
      </Fade>
    </div>
  ) : null
}

export default Splash