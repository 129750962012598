import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Menu from '../menu/Menu'
import { NavLink, useHistory } from 'react-router-dom';

const requestImageFile = require.context('../../../../assets/img/logo', true, /.*$/);

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    color: theme.palette.secondary.main,
    marginRight: '5px',
    fontWeight: '900'
  },
  subtitle: {
    color: theme.palette.secondary.main,
    alignSelf: 'center'
  },
  item: {
    color: theme.palette.secondary.main,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#EDF2F4',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  img: {
    height: '100px',
    width: '100px',
    marginRight: '2%',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      height: '50px',
      width: '50px',
    }
  },
  link: {
    textDecoration: 'none'
  }
}));

const NavBar = () => {
    const classes = useStyles();
    const history = useHistory()

    const [open, setOpen] = React.useState(false);

    const toggleMenu = (state) => {
        setOpen(state);
    };

    const items = [
      {label: 'Productos', route: '/products'},
      {label: 'Nosotros', route: '/ourcompany'},
      {label: 'Contacto', route: '/contact'}
    ]

    return (
      <Box>
        <AppBar elevation={0} position="static">
          <Menu open={open} toggleMenu={toggleMenu} items={items}/>
          <Toolbar className={classes.toolbar}>
            <Box
              display={{xs: 'block', sm: 'block', md: 'none'}}
            >
              <IconButton 
                edge="start" 
                className={classes.menuButton} 
                color="secondary" aria-label="menu"
                onClick={() => toggleMenu(true)}>
                <MenuIcon />
              </IconButton>
            </Box>
            
            <Box 
               width={1}
               display='flex'
               alignItems='center'
            >
                  <Avatar 
                    alt="corvp industries" 
                    src={requestImageFile('./corvp_logo.jpeg').default} 
                    className={classes.img} 
                    onClick={() => history.push("/")}/>
                  <Box 
                    display="flex"
                  >
                      <Typography variant="h5" className={classes.title}>
                          CORVP
                      </Typography>
                      <Typography variant="caption" className={classes.subtitle}>
                          Industries
                      </Typography>
                  </Box>              
            </Box>
       
            <Box 
                width={1}
                display={{xs: 'none', sm: 'none', md: 'flex'}}
                justifyContent='space-evenly'
              >
                {items.map((item) => (
                    <NavLink 
                      key={item.label}
                      className={classes.link} to={item.route}
                      activeStyle={{
                        borderBottomStyle: 'solid',
                        borderBottomColor: '#EF233C'
                      }}>
                      <Button>
                        <Typography variant="overline" className={classes.item}>
                            {item.label}
                        </Typography>
                      </Button>
                    </NavLink>
                ))}
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    );
}

export default NavBar