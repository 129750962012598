import './App.css';
import { ThemeProvider } from '@material-ui/core/styles';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import NavBar from './shared/components/navbar/NavBar';
import Footer from './shared/components/footer/Footer';
import Home from './modules/home/components/Home'
import Products from './modules/products/components/Products';
import Services from './modules/services/components/Services';
import OurCompany from './modules/ourcompany/components/OurCompany';
import Contact from './modules/contact/components/Contact';
import Splash from './shared/components/modal/Splash';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2196F3',
    },
    secondary: {
      main: '#2B2D42',
    },
  },
  typography: {
    fontFamily: ['Montserrat','sans-serif',].join(','),
  }
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <NavBar />
        <Splash />
        <Switch>
          <Route path="/home" component={Home} />
          <Route path="/products" component={Products} />
          <Route path="/services" component={Services} />
          <Route path="/ourcompany" component={OurCompany} />
          <Route path="/contact" component={Contact} />
          <Route path="/">
            <Redirect to="/home" />
          </Route>
        </Switch>
        <Footer />
        <ToastContainer />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
