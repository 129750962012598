import React from 'react'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const Middle = () => {

    return (
        <Box 
            textAlign="center"
            my={4}
        >
            <Typography variant="h6">
                Somos fabricantes
            </Typography>
        </Box>
    )
}

export default Middle