export const productos = [
    {
        title: 'Línea Módena',
        caracts: "Este nuevo sistema de carpinteria fue concebido como la evolución - en cuanto a tecnologia, calidad, diseño y funcionalidad - del sistema mas exitoso del mercado de aberturas en la argentina. Todos sus perfiles cuentan con Registro de Diseño Industrial, son extruidos en aleacion 6063 temple T6 segun normas IRAM y AA, y cuentan con certificacion de calidad de sus procesos segun norma ISO 9001.",
        tipos: [
            'Ventana y puerta corrediza (corte a 90°)',
            'Ventana de abrir oscilobatiente',
            'Paño fijo',
            'Ventana banderola',
            'Ventana desplazable',
            'Postigon de rebatir',
            'Ventana guillotina',
            'Puerta de rebatir',
            'Brazo de empuje'
        ],
        img: 'modena.jpeg',
        dvh: false
    },
    {
        title: 'Línea Herrero',
        caracts: 'Ventana y puerta con felpas y vidrio simple. Puertas de rebatir de 1 y 2 hojas en sus gamas medianas y pesadas, simple contacto. Paños fijos con una amplia gama de contravidrios para colocar paneles hasta 60mm. Ventiluz y banderola con visagras corridas y doble contato. Postigon con tablillas fijas tipo "Z". Amplia gama de complementos: mosquiteros, guias de cortinas, revestimientos para puertas, etc. ',
        tipos: [
            'Ventana y puerta corrediza',
            'Ventana ventiluz y banderola',
            'Puerta de rebatir linea pesada bisagra encolizada, modelos varios',
            'Brazo de empuje',
            'Ventana guillotina',
            'Puerta mosquitero'
        ],
        img: 'herrero.jpeg',
        dvh: false
    },
    {
        title: 'A30 New',
        caracts: 'Este sistema de Alta Prestacion permite la resolucion de diversas tipologias, e incorpora las formas curvas al diseño tradicional de este tipo de sistemas. A30 New fue pensado y diseñado como el sistema de mayor prestacion, estanqueidad y resistencia del mercado de aluminio. La actualidad de su diseño eurpeo, las amplias posibilidades constructivas y los accesorios de alta calidad le proporcionan al carpintero una herramienta de alta prestacion a la hora de diseñar una abertura de aluminio. Una vez mas, Aluar division elaborados le ofrece a la comunidad de la construccion un sistema de carpinteria de aluminio que cuenta con la calidad y experiencia de la empresa que lo respalda.',
        tipos: [
            'Ventana y puerta corrediza de 2, 3 y 4 hojas',
            'Ventana oscilobatiente de 1 hoja',
            'Ventana de abrir de 1 y 2 hojas',
            'Ventana de 1 hoja de abrir y oscilobatiente',
            'Ventana de abrir con paño fijo lateral',
            'Ventana banderola',
            'Ventana desplazable',
            'Paño fijo',
            'Puerta de rebatir de 1 y 2 hojas',
            'Puerta vaivén de 1 y 2 hojas',
            'Ventana y puerta plegadiza',
            'Ventana y puerta corrediza paralela'
        ],
        img: 'a30new.jpeg',
        dvh: true
    },
    {
        title: 'Corte Vesta',
        caracts: 'Es el Sistema de Carpintería que brinda una solución práctica, estética y de calidad a las viviendas tipo y edificios, de acuerdo a los estándares constructivos y arquitectónicos de la actualidad. Los perfiles cuentan con marcos tubulares de 60 mm de base que aseguran la rigidez estructural. El armado de los marcos y hojas se realiza con escuadras de tracción. La fijación al premarco se realiza con tacos reguladores.',
        tipos: [
            'Las tipologías corredizas cuentan con un sistema de hermeticidad con caja de agua y tapón central. El sistema dispone de perfiles de hoja para vidrio simple y Doble Vidriado Hermético (DVH), además de parantes reforzados.',
            'La tipología de batientes está compuesta por marcos tubulares que mejoran la prestación de armado, colocación y funcionamiento en ventanas batientes y puertas de rebatir. Con las hojas de ventanas de abrir para vidrio encapsulado se mejora el peso y la productividad en el armado.'
        ],
        img: 'aberturaCorteVesta.jpeg',
        dvh: true
    }
]

