import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Fade from 'react-reveal/Fade';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 345,
    marginTop: '10px',
    marginBottom: '10px'
  },
  media: {
    height: '300px',
    paddingTop: '56.25%',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  tipoText: {
    marginBottom: '10px'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  button: {
    backgroundColor: '#EF233C',
    color: '#FFFFFF',
    '&:hover, &:focus': {
        backgroundColor: '#ffe4c4'
    }
  }
}));

const ProductCardMobile = ({title, caracts, tipos, image, dvh = false}) => {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Fade left>
      <Card 
        className={classes.root}
        elevation={3}>
        <CardHeader 
          className={classes.header}
          title={title}
        />
        <CardMedia
          className={classes.media}
          title="abertura producto"
          image={image}
        />

        <CardActions disableSpacing>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
              <Typography variant="h6" color="textSecondary" component="p">
                Caracteristicas
              </Typography>
              <Typography className={classes.tipoText}  variant="body2" color="textSecondary" component="p">
                  {caracts}
              </Typography>
              <Typography variant="h6" color="textSecondary" component="p">
                Tipologias disponibles
              </Typography>
              {tipos.map((item) => (
                  <Typography key={item} variant="body2" color="textSecondary" component="p">
                    {item}
                  </Typography>
              ))}
          </CardContent>
          {
              dvh === true && <CardActions className={classes.actions}>
                  <Button
                  className={classes.button} 
                  size="small" 
                  color="primary">
                      <GetAppIcon /> Doble de vidrio
                  </Button>
              </CardActions>
            }
        </Collapse>
      </Card>
    </Fade>
  );
}

export default ProductCardMobile