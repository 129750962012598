import React from 'react'
import Box from '@material-ui/core/Box';
import CardCustom from './CardCustom';
import Bounce from 'react-reveal/Bounce';

const requestImageFile = require.context('../../../../assets/img/muestras', true, /.*$/);

const Cards = () => {

    return (
        <Box 
            display='flex'
            flexDirection={{xs:'column', sm:'column', md:'row'}}
            alignItems='center'
            justifyContent='space-evenly'
            mb={6}
        >
            <Bounce>
                <CardCustom 
                    title={'Puertas'} 
                    image={requestImageFile('./puerta.jpg').default} 
                    redirect='products/puertas'/>
            </Bounce>
            <Bounce>
                <CardCustom 
                    title={'Ventanas'} 
                    image={requestImageFile('./abertura1.png').default} 
                    redirect='products/ventanas'/>
            </Bounce>
            <Bounce>
                <CardCustom 
                    title={'Cerramientos'} 
                    image={requestImageFile('./cerramiento.jpg').default} 
                    redirect='products/cerramientos'/>    
            </Bounce>      
        </Box>
    )
}

export default Cards