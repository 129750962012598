import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Fade from 'react-reveal/Fade';

const requestImageFile = require.context('../../../../assets/img/ilustrations', true, /.*$/);

const useStyles = makeStyles((theme) => ({
    headerText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
        }
    },
    bodyText: {
        marginTop: '20px'
    },
    img: {
        height: '200px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '20px',
            marginBottom: '20px',
            width: '250px'
        }
    }
}))

const OurCompany = () => {
    const classes = useStyles()

    return (
        <Box 
            mx={{xs: 5, sm: 5, md: 10}} 
            my={{xs: 2, sm: 2, md: 4}} 
            height={{xs: '900px', sm: '600px', md: '350px'}} 
        >
            <Box 
                width={1}
                display="flex"
                flexDirection='column'
            >
                <Fade>
                    <Typography variant="h4" className={classes.headerText}>
                        Nosotros
                    </Typography>
                </Fade>
                <Box 
                    display='flex'
                    flexDirection={{xs: 'column', sm: 'column', md: 'row'}}
                >
                    <Fade>
                        <Typography variant="body2" className={classes.bodyText}>
                            Somos una empresa joven dedicada a la fabricación de aberturas de aluminio de primera calidad. 
                            <br/>
                            <br/>
                            Ubicados en bella vista fabricamos aberturas para obras en construcción, particulares, inmobiliarias, constructoras y arquitectos en todo el país.
                            <br/> 
                            <br/>
                            Trabajamos materiales de primera calidad para garantizar la durabilidad de nuestros productos a lo largo del tiempo.
                            Nos especializamos en la fabricación de puertas en aluminio, ventanas en aluminio de todo tipo, cerramientos para terrazas, balcones y quinchos, divisores de oficinas, postigones, ventanas mosquitero, ventanas de doble vidrio y mamparas de baño, entre otros productos.
                            <br/> 
                            <br/>
                            Realizamos trabajos a medida y asesorando a nuestros clientes sobre el tipo de abertura indicada.
                            Somos fabricantes y a su vez también nos encargamos que el cliente tenga su producto conforme y en buen estado.
                        </Typography>
                    </Fade>
                    <Box
                        display='flex'
                        justifyContent='center'
                    >
                        <Fade>
                            <img src={requestImageFile('./ourcompany.svg').default} alt="aberturas nosotros" className={classes.img}/>
                        </Fade>
                    </Box>
                </Box>

            </Box>
        </Box>
    )
}

export default OurCompany