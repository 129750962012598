import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';

const requestImageFile = require.context('../../../../assets/img/productos/cerramientos', true, /.*$/);

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '20px'
    },
    headerText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
        }
    },
    paper: {
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    img: {
        height: '200px',
        width: '200px'
    }
}))

const Cerramientos = () => {
    const classes = useStyles()

    const images = requestImageFile.keys()

    return (
        <Box 
            mx={{xs: 5, sm: 5, md: 10}} 
            my={{xs: 2, sm: 2, md: 4}} 
        >
            <Box 
                width={1}
                display="flex"
                flexDirection='column'
            >
                <Typography variant="h4" className={classes.headerText}>
                    Cerramientos
                </Typography>

            <Grid 
                container 
                zeroMinWidth
                justify="center"
                className={classes.container}
            >
                {images.map(img => (
                    <Grid 
                        key={img}
                        xs={12} sm={4} md={3} 
                        container
                    >
                        <Paper 
                            elevation={3}
                            key={img}
                            className={classes.paper}
                        >
                        <img className={classes.img} src={requestImageFile(img).default} alt={img} />
                        </Paper>
                    </Grid>
                ))}     
                <p>Comming soon...</p>
            </Grid>

            </Box>
        </Box>
    )
}

export default Cerramientos