import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ProductCardMobile from './ProductCardMobile'
import ProductCardDesk from './ProductCardDesk';
import { productos } from './../../../shared/constants/Constants';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import Ventanas from './Ventanas';
import Puertas from './Puertas';
import Cerramientos from './Cerramientos';
import { Card, CardHeader } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';

const requestImageFile = require.context('../../../../assets/img/productos/aberturas', true, /.*$/);
const requestPptfile = require.context('../../../../assets/ppt', true, /.*$/);

const useStyles = makeStyles((theme) => ({
    headerText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
        }
    },
    paper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    img: {
        height: '200px',
        width: '200px'
    },
    card: {
        width: 345,
        cursor: 'pointer',
        '&.MuiCardHeader-root': {
            alignItems: 'baseline'
        }
    }
}))

const ProductsPrincipal = () => {
    const classes = useStyles()
    const history = useHistory()

    return (
        <Box 
            mx={{xs: 5, sm: 5, md: 10}} 
            my={{xs: 2, sm: 2, md: 4}} 
        >
            <Box 
                width={1}
                display="flex"
                flexDirection='column'
            >
            <Typography variant="h4" className={classes.headerText}>
                Productos
            </Typography>

            <Box 
                display='flex'
                flexDirection='column'
                my={3}    
            >
                <Box
                    display='flex'
                    flexDirection={{xs: 'column', sm: 'column', md: 'row'}}
                    justifyContent='center'
                    alignItems='center'
                    flexGrow='grow'
                >
                    <Box m='10px' >
                        <Card 
                            className={classes.card}
                            onClick={() => history.push("products/puertas")}>
                            <CardHeader 
                                title='Puertas'
                                action={
                                    <IconButton 
                                        aria-label="ver mas"
                                        >
                                      <ChevronRightIcon />
                                    </IconButton>
                                }
                            />
                        </Card>
                    </Box>
                    <Box m='10px'>
                        <Card 
                            className={classes.card}
                            onClick={() => history.push("products/ventanas")}>
                            <CardHeader 
                                title='Ventanas'
                                action={
                                    <IconButton aria-label="ver mas">
                                      <ChevronRightIcon />
                                    </IconButton>
                                }
                            />
                        </Card>
                    </Box>
                    <Box m='10px'>
                        <Card 
                            className={classes.card}
                            onClick={() => history.push("products/cerramientos")}>
                            <CardHeader 
                                title='Cerramientos'
                                action={
                                    <IconButton aria-label="ver mas">
                                      <ChevronRightIcon />
                                    </IconButton>
                                }
                            />
                        </Card>
                    </Box>
                </Box> 
                <Box 
                    display={{xs: 'flex', sm: 'flex', md: 'none'}}
                    alignItems='center'
                    flexDirection='column'
                >
                    {productos.map((item) => (
                        <ProductCardMobile 
                            key={item.title}
                            title={item.title} 
                            caracts={item.caracts}
                            tipos={item.tipos}
                            image={requestImageFile(`./${item.img}`).default}
                            dvh={item.dvh}
                            dvhFile={requestPptfile(`./dvh.pptx`).default}
                        />
                    ))}
                    
                </Box>
                <Box
                    display={{xs: 'none', sm: 'none', md: 'flex'}}
                    flexDirection='column'
                >
                    {productos.map((item) => (
                        <ProductCardDesk 
                            key={item.title}
                            title={item.title} 
                            caracts={item.caracts}
                            tipos={item.tipos}
                            image={requestImageFile(`./${item.img}`).default}
                            dvh={item.dvh}
                            dvhFile={requestPptfile(`./dvh.pptx`).default}
                        />
                    ))}
                </Box>
            </Box>

            </Box>
        </Box>
    )
}

const Products = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}> 
                <ProductsPrincipal />
            </Route>
            <Route path={`${path}/ventanas`}> 
                <Ventanas />
            </Route>
            <Route path={`${path}/puertas`}> 
                <Puertas />
            </Route>
            <Route path={`${path}/cerramientos`}> 
                <Cerramientos />
            </Route>
        </Switch>
    )
}

export default Products